import React from "react";
import { graphql } from "gatsby";

import { useLocale } from "context/locale";

import { Why, Compare } from "page_components/Hubwork";

import Layout from "components/Layout";
import PageHeader from "components/PageHeader";
import HubInfo from "components/HubInfo";
import BottomMessage from "components/BottomMessage";

const BottomInfo = () => {
  const { t } = useLocale();
  return (
    <BottomMessage
      text={t("We encourage you to see the details of our offer")}
      button={{ label: t("See our offer"), href: t("offer_link") }}
    />
  );
};

const HubWork = ({
  location,
  pageContext: { translations },
  data: { hubwork, contact },
}) => {
  const hub_info = hubwork?.acfHubInfo?.hubinfoContent?.[0];

  return (
    <Layout
      seo={{
        title: hubwork.seo.title || hubwork.title,
        description: hubwork.seo.metaDesc || "",
      }}
      translations={translations}
      location={location}
      contact_data={contact?.acfContact}
    >
      <PageHeader label={hubwork?.title || ""} />
      {hub_info?.title && (
        <HubInfo
          title={hub_info.title}
          text={hub_info?.text}
          image={hub_info?.image?.sourceUrl}
        />
      )}
      <Why
        title={hubwork?.acfHubwork?.title}
        description={hubwork?.acfHubwork?.text}
        image={hubwork?.acfHubwork?.image?.sourceUrl}
      />
      <Compare data={hubwork?.acfHubwork?.compareItem} />
      <BottomInfo />
    </Layout>
  );
};

export default HubWork;

export const query = graphql`
  query ($id: Int!) {
    hubwork: wpPage(databaseId: { eq: $id }) {
      title
      seo {
        title
        metaDesc
      }
      acfHubInfo {
        hubinfoContent {
          title
          text
          image {
            sourceUrl
          }
        }
      }
      acfHubwork {
        title
        text
        image {
          sourceUrl
        }
        compareItem {
          icon
          label
          hubwork
          coworking
        }
      }
    }
    contact: wpPage(id: { eq: "cG9zdDoxOTE=" }) {
      acfContact {
        email
        phone
        address
      }
    }
  }
`;
