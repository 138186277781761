import "./styles.scss";
import React from "react";

const Why = ({ title, description, image }) => {
  return (
    <section className="hubwork-why">
      <div className="container">
        <div className="hubwork-why__text">
          <h2>{title}</h2>
          <p>{description}</p>
        </div>
        <div className="hubwork-why__image">
          <img src={image} alt={title} />
        </div>
      </div>
    </section>
  );
};

export default Why;
