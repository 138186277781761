import "./styles.scss";

import React from "react";
import Button from "components/Button";

const HubInfo = ({ title, text, image, withMarginTop, button }) => {
  return (
    <section className={`page-info ${withMarginTop ? "margin-top" : ""}`}>
      <div className="container">
        <div className="page-info__text">
          <h2>{title}</h2>
          <p>{text}</p>
        </div>
      </div>
      <div className="page-info__image">
        <img src={image} alt="" />
      </div>
      <div className="container">
        {!!button && <Button href={button?.href}>{button?.label}</Button>}
      </div>
    </section>
  );
};

export default HubInfo;
