import "./styles.scss";
import React from "react";

import { useLocale } from "context/locale";

import CheckMarkIcon from "icons/checkmark.svg";
import CrossIcon from "icons/cross.svg";

import PriceIcon from "icons/hubwork/price.svg";
import KeysIcon from "icons/hubwork/keys.svg";
import MailboxIcon from "icons/hubwork/mailbox.svg";
import HandsIcon from "icons/hubwork/hands.svg";
import SheetIcon from "icons/hubwork/sheet.svg";
import WebIcon from "icons/hubwork/web.svg";
import KitchenIcon from "icons/hubwork/kitchen.svg";
import BoardIcon from "icons/hubwork/board.svg";
import MessageIcon from "icons/hubwork/message.svg";

const getIcon = (icon) => {
  switch (icon) {
    case "price":
      return <PriceIcon />;
    case "keys":
      return <KeysIcon />;
    case "mailbox":
      return <MailboxIcon />;
    case "hands":
      return <HandsIcon />;
    case "sheet":
      return <SheetIcon />;
    case "web":
      return <WebIcon />;
    case "kitchen":
      return <KitchenIcon />;
    case "board":
      return <BoardIcon />;
    case "message":
      return <MessageIcon />;
    default:
      return icon;
  }
};

const Compare = ({ data }) => {
  const { t } = useLocale();
  return (
    <div className="hubwork-compare">
      <div className="container">
        <h2>{t("Choose yourself which solution is better for you")} </h2>
        <div className="hubwork-compare__content">
          <div className="hubwork-compare__content__row hubwork-compare__content__row--header">
            <div className="hubwork-compare__content__col" />
            <div className="hubwork-compare__content__col">
              <div className="hubwork-compare__content__col">
                <span>Hubwork</span>
              </div>
              <div className="hubwork-compare__content__col">
                <span>Coworking</span>
              </div>
            </div>
          </div>
          {data?.map(({ icon, label, hubwork, coworking }, index) => (
            <div key={index} className="hubwork-compare__content__row">
              <div className="hubwork-compare__content__col">
                {getIcon(icon)}
                <span>{label}</span>
              </div>
              <div className="hubwork-compare__content__col">
                <div className="hubwork-compare__content__col">
                  {hubwork ? <CheckMarkIcon /> : <CrossIcon />}
                </div>
                <div className="hubwork-compare__content__col">
                  {coworking ? <CheckMarkIcon /> : <CrossIcon />}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Compare;
